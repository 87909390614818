import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import FooterMenu from '../Menus/FooterMenu';
import FooterVirtualSport from '../Menus/FooterVirtualSport';
import GamesSlots from '../Menus/GamesSlots';
import Racing from '../Menus/Racing';
import Lottery from '../Menus/Lottery';
import FooterLiveCasino from '../Menus/FooterLiveCasino';
import { wrapper, color, border, socialIcon, breakpoints, flex } from '../../utils/style';
import { mq } from '../../utils/mediaQuery';
import IcomoonIcon from '../shared/IcomoonIcon';
import WithdrawPage from '../Content/Withdraw/WithdrawPage';
import FooterBall from '../shared/FooterBall';
import FreshChatComponent from '../Content/Chat/fresh-chat/FreshChat';
import PopupWindow from '../shared/PopupWindow';

// Images
import discover from '../../img/discover-ico.svg';
import mastercard from '../../img/mastercard-ico.svg';
import paypal from '../../img/paypal-ico.svg';
import visa from '../../img/visa-ico.svg';
import phone from '../../img/tel.svg';

const Wrapper = styled('div')`
  ${wrapper};
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  border-radius: ${border.borderRadius4};
  padding: 90px 0 60px 0;

  @media (max-width: ${breakpoints.md}) {
    flex-wrap: wrap;
    .desktop {
      display: none;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    margin-bottom: 0;
    padding: 0 0 25px 0;
  }
`;

const MenuSection = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: 240px;
  height: 240px;
  width: 55%;
  flex-direction: column;
  .menu-col {
    min-height: min-content;
  }
  @media (max-width: ${breakpoints.tl}) {
    width: 47%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: stretch;
    min-height: 355px;
  }
  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(3, 40px);
    grid-column-gap: 20px;
    grid-template-columns: 50% 50%;
    padding: 0 25px 20px;
    margin: 20px 0 20px 0;
    border-bottom: 1px solid ${color.martinique};
    justify-content: space-between;
    min-height: max-content;
    height: max-content;
    :after {
      display: none;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    margin-left: 25px;
  }

  .h4 {
    font-weight: 400;
    color: ${color.lightningYellow};
    text-transform: uppercase;
    font-size: 15px;
    white-space: nowrap;
    margin: 0 10px 10px;
    position: relative;
    a {
      color: ${color.lightningYellow};
      white-space: pre-wrap;
    }
    .iconImage {
      position: absolute;
      left: -28px;
    }

    & svg {
      margin-left: -19px;
      margin-right: 10px;
    }
  }

  ul {
    list-style: none;
    max-width: 75%;
    padding: 0;
  }

  li {
    position: relative;
    vertical-align: top;
    padding: 0 10px;
    margin-bottom: 0.5rem;
    font-size: 15px;

    a {
      color: ${color.grey};
    }
    a:hover,
    a:active,
    .active {
      color: ${color.white};
      text-decoration: none;
    }

    a:hover + span,
    a:active + span,
    .active + span {
      border-right: 2px solid ${color.white};
      border-bottom: 2px solid ${color.white};
    }
    a:before {
      content: '';
      height: 4px;
      width: 4px;
      position: absolute;
      left: -5px;
      top: 10px;
      display: block;
      background-color: ${color.grey};
      border-radius: 50%;
    }

    .arrow {
      width: 9px;
      height: 9px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      transform: rotate(-45deg) translate(-14px, -7px);
      border-right: 2px solid ${color.grey};
      border-bottom: 2px solid ${color.grey};
      display: none;
    }
  }
`;

const RightsReservedItems = styled('div')`
  width: 100%;
  height: max-content;
  border-top: 1px solid ${color.martinique};
  padding-top: 30px;
  margin-bottom: 50px;
  position: relative;

  ${flex};
  flex-direction: column;

  .social-buttons {
    //margin: 3px 7px 0 0;
    padding: 5px 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    a {
      margin: 0 5px;
    }

    svg {
      vertical-align: baseline;
    }
  }

  .copy {
    height: 23px;
    opacity: 0.4;
    background-color: var(--white);
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: normal;
    color: var(--white);

    a {
      margin: 0 0 0 10px;
      text-decoration: underline;
    }
    a:hover,
    a:active {
      text-decoration: none;
      color: ${color.white};
    }
  }

  @media (max-width: ${breakpoints.md}) {
    padding-top: 30px;
    margin-bottom: 80px;
  }
`;

const PaymentsVars = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;

  .payment-wrapper {
    background-color: ${color.transparentWhite2};
    margin-bottom: 11px;
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${border.borderRadius14};
    padding: 5px;
    max-width: calc(50% - 5px);
    width: 50%;

    .iconImage {
      max-width: 104px;
      margin: 0;
    }
  }
`;

const SubMenu = styled('ul')`
  display: flex;
  flex-direction: column;

  & > li > a {
    &:hover {
      color: ${color.white};
    }
  }

  @media (max-width: ${breakpoints.md}) and (max-height: ${breakpoints.md}) {
    width: 100%;
    margin-left: 20px;
  }
`;

const ContactInfo = styled('div')`
  width: 218px;
  display: flex;
  .sbobet-seo {
    padding: 10px 0 0 15px;
    .desktop {
      ul {
        list-style: none;
        a {
          position: relative;
          &:before {
            content: '';
            height: 4px;
            width: 4px;
            position: absolute;
            left: -12px;
            top: 10px;
            display: block;
            background-color: ${color.grey};
            border-radius: 50%;
          }
        }
      }
    }
  }
  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    .sbobet-seo {
      margin-bottom: 20px;
      width: 100%;
    }
    .sbobet-seo .desktop {
      display: block;
    }
  }
  .h4 {
    margin-left: 50px;
    color: ${color.grey};
    font-weight: 400;
    @media (max-width: ${breakpoints.md}) {
      display: none;
    }
  }
  .social-buttons {
    @media (max-width: ${breakpoints.md}) {
      order: 2;
    }
    .button {
      margin: 3px 7px 15px 0;
      padding: 5px 10px 8px;
      border-radius: 50%;

      svg {
        vertical-align: baseline;
      }
    }
  }
  .copy {
    margin: 25px 0 0;
    font-size: 12px;
    color: ${color.grey};
    @media (max-width: ${breakpoints.md}) {
      order: 2;
    }
  }
`;

const TextLink = styled('a')`
  ${socialIcon};
  span {
    display: inline-block;
    vertical-align: top;
    line-height: 1.2;
    color: ${color.white};
  }
  img {
    top: 0;
    left: 0;
    position: absolute;
  }
`;

const Phone = styled.div`
  height: 44px;
  background: rgb(24, 23, 79);
  background: linear-gradient(88deg, rgb(24, 23, 79) 57%, rgb(33, 30, 151) 97%);
  border-radius: 14px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  display: flex;
  padding: 0 10%;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
  }

  @media (orientation: portrait) {
    ${mq('xxs')} {
      padding: 0 20%;
    }
  }
`;

const Lnwasia = styled.div`
  height: 44px;
  background: rgb(24, 23, 79);
  background: linear-gradient(88deg, rgb(24, 23, 79) 57%, rgb(33, 30, 151) 97%);
  border-radius: 14px;
  color: ${color.lightGreen};
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0 10%;
  cursor: pointer;

  & > svg {
    margin-right: 15px;
  }

  &:hover {
    background-color: ${color.lightGreen};
    color: ${color.white};

    & > svg {
      & path {
        fill: ${color.white};
      }
    }
  }

  white-space: nowrap;
  @media (orientation: portrait) {
    ${mq('xxs')} {
      padding: 0 25%;
    }
  }
`;

// REFACTOR
// Full refactor with decompose
export default () => (
  <footer>
    <Wrapper>
      <ContactInfo>
        <div className="menu-col">
          <div className="contacts">
            <Phone onClick={() => window.open('tel:025088798', '_blank')} elType="phone">
              <img src={phone} alt="phone" />
              02 508 87 98
            </Phone>
            <Lnwasia onClick={() => window.open('https://line.me/R/ti/p/%40yvx7063g', '_blank')}>
              <IcomoonIcon className="" icon="line-id" size={25} />
              {process.env.LINE_NAME}
            </Lnwasia>
            <PaymentsVars>
              <div className="payment-wrapper">
                <img className="iconImage" src={visa} width="72" height="32" alt="visa" />
              </div>
              <div className="payment-wrapper">
                <img className="iconImage" src={mastercard} width="72" height="32" alt="mastercard" />
              </div>
              <div className="payment-wrapper">
                <img className="iconImage" width="72" height="32" src={discover} alt="discover" />
              </div>
              <div className="payment-wrapper">
                <img className="iconImage" width="72" height="32" src={paypal} alt="paypal" />
              </div>
            </PaymentsVars>
          </div>
        </div>
      </ContactInfo>
      <MenuSection>
        <div className="menu-col">
          <FooterMenu />
        </div>

        <div className="menu-col">
          <FooterVirtualSport />
        </div>

        <div className="menu-col">
          <GamesSlots />
        </div>

        <div className="menu-col">
          <FooterLiveCasino />
        </div>
        <div className="menu-col">
          <Racing />
        </div>
        <div className="menu-col">
          <Lottery />
        </div>
      </MenuSection>
      <SubMenu>
        <li>
          <Link to="/sbobet/">Sbobet นับร้อยนับพันคู่ต่อสัปดาห์</Link>
        </li>
        <li>
          <Link to="/baanpolball/">Baanpolball</Link>
        </li>
        <li>
          <Link to="/about-us/">เกี่ยวกับเรา</Link>
        </li>
        <li>
          <Link to="/online-gaming/">เดิมพันออนไลน์</Link>
        </li>
        <li>
          <Link to="/online-casino/">เล่นคาสิโนออนไลน์</Link>
        </li>
        <li>
          <Link to="/online-football/">บอลออนไลน์</Link>
        </li>
        <li>
          <Link to="/casinoonline/">ทางเข้าคาสิโนออนไลน์</Link>
        </li>
        <li>
          <Link to="/uefa-world-cup-football-new-update/">ยูฟ่า</Link>
        </li>
      </SubMenu>
    </Wrapper>
    <RightsReservedItems>
      <div className="copy">
        &copy;{`${process.env.SITE_NAME_SHORT}`} 2020. All Rights Reserved &nbsp; <Link to="/sitemap/">Sitemap</Link>
      </div>
      <div className="social-buttons">
        <TextLink className="" href={process.env.FACEBOOK_LINK} aria-label="Gclub Facebook">
          <IcomoonIcon className="" icon="facebook" size={40} color={color.lightningYellow} />
        </TextLink>
        <TextLink className="" href={process.env.TWITTER_LINK} aria-label="Gclub Twitter">
          <IcomoonIcon className="" icon="twitter" size={40} color={color.lightningYellow} />
        </TextLink>
        <TextLink className="" href={process.env.YOUTUBE_LINK} aria-label="Gclub Youtube">
          <IcomoonIcon className="" icon="youtube" size={40} color={color.lightningYellow} />
        </TextLink>
      </div>
      <FooterBall />
    </RightsReservedItems>
    <PopupWindow title="Withdraw Popup" popupId="withdraw-popup">
      <WithdrawPage />
    </PopupWindow>
    <FreshChatComponent />
  </footer>
);
